import ContentBlocks from '@/modules/foundation/sanity/content-blocks/ContentBlocks';
import PageHead from '@/modules/foundation/sanity/page-head/PageHead';

import type {FrontPageQueryResult} from './query';

interface Props {
	page: FrontPageQueryResult;
}

export default function FrontPage({page}: Props) {
	const {content, pageHeadings} = page ?? {};

	return (
		<>
			{pageHeadings && <PageHead pageHeads={pageHeadings} />}
			{content && <ContentBlocks content={content} />}
		</>
	);
}
