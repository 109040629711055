'use client';

import {useQuery} from '@/sanity/loader/useQuery';

import FrontPage from './FrontPage';
import type {FrontPageQueryResult} from './query';
import {FRONTPAGE_QUERY} from './query';

type Props = {
	initial: FrontPageQueryResult;
};

export default function FrontPageDraft(props: Props) {
	const {initial} = props;
	const {data: page} = useQuery<FrontPageQueryResult>(initial, FRONTPAGE_QUERY);

	return <FrontPage page={page} />;
}
